import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { customDirective } from './directives/customDirective';
import {reClick} from './directives/reClick';

const app = createApp(App);
app.component('VueDatePicker', VueDatePicker);
app.directive('reClick', reClick);
app.directive('custom', customDirective);
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(store).use(router).mount('#app')
