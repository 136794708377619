
import { Directive, App, VNode } from 'vue';

const reClick: Directive = {
  mounted(el: any, binding: any) {
      el.addEventListener('click', () => {
          if (!el.disabled) {
              el.disabled = true
              setTimeout(() => {
                  el.disabled = false
              }, binding.value || 500)
          }
      })
  }
}

export {reClick};