import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登陆'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      title: '首页'
    },
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue'),
    redirect: '/index/welcome',
    children: [
      {
        path: 'welcome',
        name: 'welcome',
        meta: {
          title: '欢迎'
        },
        component: () => import(/* webpackChunkName: "welcome" */ '../views/welcome.vue')
      },
      {
        path: 'supplierManagement',
        name: 'supplierManagement',
        meta: {
          title: '供应商管理'
        },
        component: () => import(/* webpackChunkName: "supplierManagement" */ '../views/supplierManagement/index.vue'),
        children: [
          {
            path: 'supplierManagement',
            name: 'supplierManagement',
            meta: {
              title: '供应商管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "supplierManagement" */ '../views/supplierManagement/supplierManagement.vue')
          },
          {
            path: 'commoditySupplyManagement',
            name: 'commoditySupplyManagement',
            meta: {
              title: '商品供货管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "commoditySupplyManagement" */ '../views/supplierManagement/commoditySupplyManagement.vue')
          },
          {
            path: 'supplierSupplyManagement',
            name: 'supplierSupplyManagement',
            meta: {
              title: '供应商供货管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "supplierSupplyManagement" */ '../views/supplierManagement/supplierSupplyManagement.vue')
          }
        ]
      },
      {
        path: 'goodsManagement',
        name: 'goodsManagement',
        meta: {
          title: '商品管理'
        },
        component: () => import(/* webpackChunkName: "goodsManagement" */ '../views/goodsManagement/index.vue'),
        children: [
          {
            path: 'goodsCategory',
            name: 'goodsCategory',
            meta: {
              title: '商品分类',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "goodsCategory" */ '../views/goodsManagement/goodsCategory.vue')
          },
          {
            path: 'customableGoods',
            name: 'customableGoods',
            meta: {
              title: '可订商品管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "customableGoods" */ '../views/goodsManagement/customableGoods.vue')
          },
          {
            path: 'addGoods',
            name: 'addGoods',
            meta: {
              title: '新增/编辑商品',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "addGoods" */ '../views/goodsManagement/addGoods.vue')
          }
        ]
      },
      {
        path: 'orderManagement',
        name: 'orderManagement',
        meta: {
          title: '采购管理'
        },
        component: () => import(/* webpackChunkName: "orderManagement" */ '../views/orderManagement/index.vue'),
        children: [
          {
            path: 'availableOrder',
            name: 'availableOrder',
            meta: {
              title: '采购申请单',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "availableOrder" */ '../views/orderManagement/availableOrder.vue')
          },
          {
            path: 'addOrder',
            name: 'addOrder',
            meta: {
              title: '新增/修改采购申请单',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "addOrder" */ '../views/orderManagement/addOrder.vue')
          },
          {
            path: 'orderDetail',
            name: 'orderDetail',
            meta: {
              title: '订单详情',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "orderDetail" */ '../views/orderManagement/orderDetails.vue')
          },
          {
            path: 'myApplication',
            name: 'myApplication',
            meta: {
              title: '我的申请',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "myApplication" */ '../views/orderManagement/myApplication.vue')
          },
          {
            path: 'preOrder',
            name: 'preOrder',
            meta: {
              title: '采购任务',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "preOrder" */ '../views/orderManagement/preOrder.vue')
          },
          {
            path: 'purchaseOrder',
            name: 'purchaseOrder',
            meta: {
              title: '采购单',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "purchaseOrder" */ '../views/orderManagement/purchaseOrder.vue')
          },
          {
            path: 'auditList',
            name: 'auditList',
            meta: {
              title: '审核列表',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "auditList" */ '../views/orderManagement/auditList.vue')
          },
          {
            path: 'auditOrder',
            name: 'auditOrder',
            meta: {
              title: '审核申请单',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "auditOrder" */ '../views/orderManagement/auditOrder.vue')
          }
        ]
      },
      {
        path: 'examineManagement',
        name: 'examineManagement',
        meta: {
          title: '验收管理'
        },
        component: () => import(/* webpackChunkName: "examineManagement" */ '../views/examineManagement/index.vue'),
        children: [
          {
            path: 'receivingManagement',
            name: 'receivingManagement',
            meta: {
              title: '待收单管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "receivingManagement" */ '../views/examineManagement/receivingManagement.vue')
          },
          {
            path: 'receiptDetails',
            name: 'receiptDetails',
            meta: {
              title: '待收单详情',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "receiptDetails" */ '../views/examineManagement/receiptDetails.vue')
          },
          {
            path: 'receivingDetail',
            name: 'receivingDetail',
            meta: {
              title: '收货详情',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "receivingDetail" */ '../views/examineManagement/receivingDetail.vue')
          },
          {
            path: 'damageRecord',
            name: 'damageRecord',
            meta: {
              title: '损坏记录',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "damageRecord" */ '../views/examineManagement/damageRecord.vue')
          },
          {
            path: 'receivingRecord',
            name: 'receivingRecord',
            meta: {
              title: '收货记录',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "receivingRecord" */ '../views/examineManagement/receivingRecord.vue')
          },
          {
            path: 'receivingRecordDetails',
            name: 'receivingRecordDetails',
            meta: {
              title: '收货记录详情',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "receivingRecordDetails" */ '../views/examineManagement/receivingRecordDetails.vue')
          },
          {
            path: 'damageRecordDetail',
            name: 'damageRecordDetail',
            meta: {
              title: '损坏记录详情',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "damageRecordDetail" */ '../views/examineManagement/damageRecordDetail.vue')
          },
          {
            path: 'returnManagement',
            name: 'returnManagement',
            meta: {
              title: '退货管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "returnManagement" */ '../views/examineManagement/returnManagement.vue')
          },
          {
            path: 'returnManagementDetail',
            name: 'returnManagementDetail',
            meta: {
              title: '详情',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "returnManagementDetail" */ '../views/examineManagement/returnManagementDetail.vue')
          },
        ]
      },
      {
        path: 'settlementManagement',
        name: 'settlementManagement',
        meta: {
          title: '结算管理'
        },
        component: () => import(/* webpackChunkName: "settlementManagement" */ '../views/settlementManagement/index.vue'),
        children: [
          {
            path: 'prepaymentApplicationManagement',
            name: 'prepaymentApplicationManagement',
            meta: {
              title: '预付款申请单管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "prepaymentApplicationManagement" */ '../views/settlementManagement/prepaymentApplicationManagement.vue')
          },
          {
            path: 'generatePrepaymentRequest',
            name: 'generatePrepaymentRequest',
            meta: {
              title: '生成预付款申请单',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "generatePrepaymentRequest" */ '../views/settlementManagement/generatePrepaymentRequest.vue')
          },
          {
            path: 'prepaymentApplicationManagementDetail',
            name: 'prepaymentApplicationManagementDetail',
            meta: {
              title: '预付款申请单详情',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "prepaymentApplicationManagementDetail" */ '../views/settlementManagement/prepaymentApplicationManagementDetail.vue')
          },
          {
            path: 'myPrepaymentRequest',
            name: 'myPrepaymentRequest',
            meta: {
              title: '我的预付申请',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "myPrepaymentRequest" */ '../views/settlementManagement/myPrepaymentRequest.vue')
          },
          {
            path: 'prepaymentRequestReview',
            name: 'prepaymentRequestReview',
            meta: {
              title: '预付申请审核',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "prepaymentRequestReview" */ '../views/settlementManagement/prepaymentRequestReview.vue')
          },
          {
            path: 'reviewAdvancePaymentRequest',
            name: 'reviewAdvancePaymentRequest',
            meta: {
              title: '审核预付款申请单',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "reviewAdvancePaymentRequest" */ '../views/settlementManagement/reviewAdvancePaymentRequest.vue')
          },
          {
            path: 'prepaymentRequestReviewDetail',
            name: 'prepaymentRequestReviewDetail',
            meta: {
              title: '审核预付款申请单详情',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "prepaymentRequestReviewDetail" */ '../views/settlementManagement/prepaymentRequestReviewDetail.vue')
          },
          {
            path: 'prepaymentList',
            name: 'prepaymentList',
            meta: {
              title: '预付付款',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "prepaymentList" */ '../views/settlementManagement/prepaymentList.vue')
          },
          {
            path: 'prepayment',
            name: 'prepayment',
            meta: {
              title: '付款',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "prepayment" */ '../views/settlementManagement/prepayment.vue')
          },
          {
            path: 'settlementManagement',
            name: 'settlementManagement',
            meta: {
              title: '结算管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "settlementManagementList" */ '../views/settlementManagement/settlementManagement.vue')
          },
          {
            path: 'settlementManagementDetail',
            name: 'settlementManagementDetail',
            meta: {
              title: '结算详情',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "settlementManagementDetail" */ '../views/settlementManagement/settlementManagementDetail.vue')
          },
          {
            path: 'paymentRequisitionManagement',
            name: 'paymentRequisitionManagement',
            meta: {
              title: '付款申请单管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "paymentRequisitionManagement" */ '../views/settlementManagement/paymentRequisitionManagement.vue')
          },
          {
            path: 'generatePaymentRequest',
            name: 'generatePaymentRequest',
            meta: {
              title: '生成付款申请单',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "generatePaymentRequest" */ '../views/settlementManagement/generatePaymentRequest.vue')
          },
          {
            path: 'paymentRequisitionManagementDetail',
            name: 'paymentRequisitionManagementDetail',
            meta: {
              title: '详情',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "paymentRequisitionManagementDetail" */ '../views/settlementManagement/paymentRequisitionManagementDetail.vue')
          },
          {
            path: 'myPaymentRequest',
            name: 'myPaymentRequest',
            meta: {
              title: '我的付款申请单',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "myPaymentRequest" */ '../views/settlementManagement/myPaymentRequest.vue')
          },
          {
            path: 'myGeneratePaymentRequest',
            name: 'myGeneratePaymentRequest',
            meta: {
              title: '生成付款申请单',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "myGeneratePaymentRequest" */ '../views/settlementManagement/myGeneratePaymentRequest.vue')
          },
          {
            path: 'myGeneratePrepaymentRequest',
            name: 'myGeneratePrepaymentRequest',
            meta: {
              title: '我的生成付款申请单',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "myGeneratePrepaymentRequest" */ '../views/settlementManagement/myGeneratePrepaymentRequest.vue')
          },
          {
            path: 'paymentRequestReview',
            name: 'paymentRequestReview',
            meta: {
              title: '付款申请单审核',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "paymentRequestReview" */ '../views/settlementManagement/paymentRequestReview.vue')
          },
          {
            path: 'reviewPaymentRequests',
            name: 'reviewPaymentRequests',
            meta: {
              title: '审核付款申请单',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "reviewPaymentRequests" */ '../views/settlementManagement/reviewPaymentRequests.vue')
          },
          {
            path: 'paymentRequestReviewDetail',
            name: 'paymentRequestReviewDetail',
            meta: {
              title: '审核付款申请单详情',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "paymentRequestReviewDetail" */ '../views/settlementManagement/paymentRequestReviewDetail.vue')
          },
          {
            path: 'collectionManagement',
            name: 'collectionManagement',
            meta: {
              title: '付款管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "collectionManagement" */ '../views/settlementManagement/collectionManagement.vue')
          },
          {
            path: 'financialPayment',
            name: 'financialPayment',
            meta: {
              title: '财务付款',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "financialPayment" */ '../views/settlementManagement/financialPayment.vue')
          },
          {
            path: 'financialPaymentDetail',
            name: 'financialPaymentDetail',
            meta: {
              title: '财务付款详情',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "financialPaymentDetail" */ '../views/settlementManagement/financialPaymentDetail.vue')
          },
          {
            path: 'payment',
            name: 'payment',
            meta: {
              title: '财务付款',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "payment" */ '../views/settlementManagement/payment.vue')
          },
          {
            path: 'addCollection',
            name: 'addCollection',
            meta: {
              title: '财务收款',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "addCollection" */ '../views/settlementManagement/addCollection.vue')
          },
          {
            path: 'collectionManagementDetail',
            name: 'collectionManagementDetail',
            meta: {
              title: '付款详情',
              grade: 2
            },
            component: () => import(/* webpackChunkName: "collectionManagementDetail" */ '../views/settlementManagement/collectionManagementDetail.vue')
          },
          {
            path: 'unpaidManagement',
            name: 'unpaidManagement',
            meta: {
              title: '预计付款管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "unpaidManagement" */ '../views/settlementManagement/unpaidManagement.vue')
          },
          {
            path: 'customerBalance',
            name: 'customerBalance',
            meta: {
              title: '供应商余额管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "customerBalance" */ '../views/settlementManagement/customerBalance.vue')
          },
          {
            path: 'customerReconciliation',
            name: 'customerReconciliation',
            meta: {
              title: '供应商对账管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "customerReconciliation" */ '../views/settlementManagement/customerReconciliation.vue')
          }
        ]
      },
      {
        path: 'operatorManagement',
        name: 'operatorManagement',
        meta: {
          title: '操作员管理'
        },
        component: () => import(/* webpackChunkName: "operatorManagement" */ '../views/operatorManagement/index.vue'),
        children: [
          {
            path: 'operator',
            name: 'operator',
            meta: {
              title: '账员管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "operator" */ '../views/operatorManagement/operator.vue')
          },
          {
            path: 'role',
            name: 'role',
            meta: {
              title: '角色管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "role" */ '../views/operatorManagement/role.vue')
          }
        ]
      },
      {
        path: 'systemSettings',
        name: 'systemSettings',
        meta: {
          title: '系统设置'
        },
        component: () => import(/* webpackChunkName: "systemSettings" */ '../views/systemSettings/index.vue'),
        children: [
          {
            path: 'auditProcess',
            name: 'auditProcess',
            meta: {
              title: '采购类型管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "auditProcess" */ '../views/systemSettings/auditProcess.vue')
          },
          {
            path: 'paymentAudit',
            name: 'paymentAudit',
            meta: {
              title: '付款审核管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "paymentAudit" */ '../views/systemSettings/paymentAudit.vue')
          },
          {
            path: 'advancePaymentAudit',
            name: 'advancePaymentAudit',
            meta: {
              title: '预付款审核管理',
              grade: 1
            },
            component: () => import(/* webpackChunkName: "advancePaymentAudit" */ '../views/systemSettings/advancePaymentAudit.vue')
          }
          
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});


router.beforeEach((to, from, next) => {
  if (typeof (to.meta?.title) === 'string') {
    window.document.title = to.meta.title;
  }
  next();
});

export default router;
