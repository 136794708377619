import { createStore } from 'vuex'

interface RootState {
  path: string
  name: string
}

interface Breadcrumb {
  path: string
  name: string
  grade: number
}

export default createStore({
  state: {
    navigationArr: [] as RootState[],
    breadcrumbArr: [] as Breadcrumb[]
  },
  getters: {
  },
  mutations: {
    /**
     * 设置顶部导航
     * @param state state
     * @param data 导航对象
     */
    setNavigationArr(state, data) {
      const temp = state.navigationArr.find((item) => item.path === data.path);
      if (!temp) {
        state.navigationArr.push({
          path: data.path,
          name: data.meta.title
        });
      }
    },
    delNavigation(state, index) {
      state.navigationArr.splice(index, 1);
    },
    setBreadcrumb(state, data) {
      if (data.meta.grade == 1) {
        state.breadcrumbArr = [{
         name: data.meta.title,
         path: data.path,
         grade: data.meta.grade
        }];
      } else {
        state.breadcrumbArr.push({
          name: data.meta.title,
          path: data.path,
          grade: data.meta.grade
        });
      }
    },
    clear(state) {
      state.navigationArr = [];
      state.breadcrumbArr = [];
    }
  },
  actions: {
  },
  modules: {
  }
})
