
import { Directive, App, VNode } from 'vue';

const customDirective: Directive = {
  mounted(el: HTMLElement, binding, vnode: VNode) {
    // 从浏览器缓存中获取ID值
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo') as string);
    if (!userInfo) {
      el.style.display = 'none';
      return;
    }
    if (userInfo.info.is_supper != 1) {
      if (userInfo.roles) {
        const roles: string[] = userInfo.roles[0].permissions.split(',')
        if (typeof binding.value == 'object') {
          let is = false;
          for (let i in binding.value) {
            if (roles.indexOf(binding.value[i]) != -1) {
              is = true;
              break;
            }
          }
          if (is) {
            el.style.display = '';
          } else {
            el.style.display = 'none';
          }
        } else {
          if (roles.indexOf(binding.value.toString()) != -1) {
            el.style.display = ''; // 显示元素
          } else {
            el.style.display = 'none'; // 隐藏元素
          }
        }
      }
    }
  },
};

export { customDirective };
